<template>
  <ul
    :id="
      isAuthenticated
        ? 'main-menu-navigation-connected'
        : 'main-menu-navigation'
    "
    class="nav navbar-nav"
  >
    <component
      :is="resolveNavComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { BButton } from "bootstrap-vue";
import HorizontalNavMenuHeaderLink from "../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue";
import HorizontalNavMenuHeaderGroup from "../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BButton,
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const resolveNavComponent = (item) => {
      if (item.children) return "horizontal-nav-menu-header-group";
      return "horizontal-nav-menu-header-link";
    };

    return {
      resolveNavComponent,
    };
  },
  computed: {
    ...mapState("auth", {
      isAuthenticated: "isAuthenticated",
    }),
    // -------------------------------------
  },
  // mounted() {
  //   console.log("this.items::: ⭕️", this.items);
  // },
};
</script>
