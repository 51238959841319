import utilsService from "@/services/utils/utils.service";
let forms = utilsService.getListFormulaires();
forms = forms.map((e, i) => {
  const temp = JSON.parse(JSON.stringify(e));
  temp.action = "access";
  temp.resource = e.resource || "";
  return temp;
});

// oder folder by sector
let sectors = {
  UEH: {
    title: "sectors.hebergement",
    icon: "UsersIcon",
    isLaIcon: true,
    laIcon: "la-building",
    action: "access",
    resource: "hebergement",
    children: [],
  },
  UEAV: {
    title: "sectors.agency_travel",
    icon: "UsersIcon",
    isLaIcon: true,
    laIcon: "la-plane",
    action: "access",
    resource: "voyage",
    children: [],
  },
  UEGT: {
    title: "sectors.tourism_guide",
    icon: "UsersIcon",
    isLaIcon: true,
    laIcon: "la-map-marked-alt",
    action: "access",
    resource: "guide",
    children: [],
  },
  UER: {
    title: "sectors.restaurant",
    icon: "UsersIcon",
    isLaIcon: true,
    laIcon: "la-utensils",
    action: "access",
    resource: "restauration",
    children: [],
  },
};
forms.map((e, i) => {
  switch (e.sector) {
    case "UEH":
      sectors.UEH.children.push(e);
      break;
    case "UEAV":
      sectors.UEAV.children.push(e);
      break;
    case "UEGT":
      sectors.UEGT.children.push(e);
      break;
    case "UER":
      sectors.UER.children.push(e);
      break;
    default:
      break;
  }
});

const routes = [
  {
    header: "nav_horizontal.requests.title",
    icon: "BookIcon",
    isLaIcon: true,
    laIcon: "la-folder-open",
    action: "access",
    resource: "demandes",
    children: [{
      title: "nav_horizontal.requests.all",
      isLaIcon: true,
      laIcon: "la-folder-open",
      route: "all-requests",
      action: "access",
      resource: "demandes",
    }, sectors.UEAV, sectors.UEGT, sectors.UEH, sectors.UER],
  },
];
export default routes;
