import utilsService from "@/services/utils/utils.service";

const { isAdmin } = utilsService.currentUserUtils();
const admin = isAdmin ? true : false;

export default [
  {
    header: "nav_horizontal.parameter.title",
    icon: "BookIcon",
    isLaIcon: true,
    laIcon: "la-cog",
    action: "access",
    resource: "app_configuration",
    children: [
      {
        title: "nav_horizontal.parameter.operators",
        resource: "operateurs",
        action: "administrate",
        isLaIcon: true,
        laIcon: "la-user-friends",
        route: "operateurs-list",
      },
      {
        title: "nav_horizontal.parameter.claims",
        isLaIcon: true,
        laIcon: "la-gavel",
        action: "access",
        resource: "complaint",
        route: "plainte-list",
      },
      {
        title: 'nav_horizontal.appeals',
        isLaIcon: true,
        laIcon: 'la-comments',
        action: 'list',
        resource: 'appeal',
        route: 'appeal-admin-list',
      },
      {
        title: "nav_horizontal.parameter.content_management",
        icon: "BookIcon",
        isLaIcon: true,
        laIcon: "la-file-alt",
        action: "access",
        resource: "app_configuration",
        children: [
          {
            title: "nav_horizontal.parameter.information_content",
            isLaIcon: true,
            laIcon: "la-info-circle",
            route: "contenu-informationnel-list",
            resource: "info_client",
            action: "access",
          },
          {
            title: "nav_horizontal.parameter.faq",
            route: "configs-faqs-list",
            icon: "UsersIcon",
            isLaIcon: true,
            laIcon: "la-question",
            resource: "faq",
            action: "access",
          },
          {

            title: "nav_horizontal.parameter.investor_mails",
            route: "config-invertors-mails-list",
            icon: "UsersIcon",
            isLaIcon: true,
            laIcon: "la-envelope",
            action: "access",
            resource: "app_administration",
          },
        ]
      },
      admin == true && {
        title: "nav_horizontal.parameter.avanced_config",
        icon: "BookIcon",
        isLaIcon: true,
        laIcon: "la-cogs",
        action: "access",
        resource: "app_configuration",
        children: [
          {
            title: "nav_horizontal.parameter.users",
            isLaIcon: true,
            laIcon: "la-users",
            route: "users-list",
            icon: "LockIcon",
            action: "access",
            resource: "app_administration",
          },
          {
            title: "nav_horizontal.parameter.roles",
            route: "config-roles",
            icon: "UsersIcon",
            isLaIcon: true,
            laIcon: "la-user-cog",
            action: "access",
            resource: "app_administration",
          },
          {
            title: "nav_horizontal.parameter.parameters",
            route: "config-parametres",
            isLaIcon: true,
            laIcon: "la-wrench",
            action: "access",
            resource: "app_configuration",
          },
        ]
      },



      {
        title: "nav_horizontal.parameter.grille_config",
        isLaIcon: true,
        laIcon: "la-clipboard",
        route: "config-forms",
        action: "access",
        resource: "app_configuration",
      },
      // {
      //   title: "Secteurs",
      //   icon: "LockIcon",
      //   isLaIcon: true,
      //   laIcon: "la-box",
      //   action: "access",
      //   resource: "app_configuration",
      // },
      // {
      //   title: 'FAQs',
      //   route: 'configs-faqs-list',
      //   icon: 'UsersIcon',
      //   isLaIcon: true,
      //   laIcon: 'la-wrench',
      //   action: 'access',
      //   resource: 'faq',
      // },
    ],
  },
];
