import utilsService from '@/services/utils/utils.service'


export default [
  {
    header: "nav_horizontal.stats.title",
    icon: "BookIcon",
    isLaIcon: true,
    laIcon: "la-toolbox",
    action: "access",
    resource: "app_configuration",
    children: [
      {
        title: "nav_horizontal.stats.tourism_jobs",
        icon: "Chart",
        isLaIcon: true,
        laIcon: "la-chart-bar",
        route: "tourism-jobs",
        action: "access",
        resource: "tourism_jobs",
      },
      {
        title: "nav_horizontal.stats.interns",
        route: "stats",
        isLaIcon: true,
        laIcon: "la-chart-bar",
        action: "access",
        resource: "demandes",
      },
    ],
  },

]
