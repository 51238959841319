export default [
  {
    title: "nav_horizontal.my_esta",
    isLaIcon: true,
    laIcon: "la-building",
    action: "access",
    resource: "profile",
    route: "etablissement"
  },
];
