<template>
  <div>
    <button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      v-b-toggle.notifications-sidebar-right
      @click="markUnreadNotificationsAsRead"
      class="btn"
    >
      <feather-icon
        :badge="newNotifications"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </button>
    <b-sidebar
      id="notifications-sidebar-right"
      bg-variant="white"
      width="490px"
      right
      backdrop
      shadow
    >
      <div class="px-2 mt-1">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
          <b-badge pill variant="light-primary">
            {{ newNotifications }}</b-badge
          >
        </div>
        <div
          v-if="isLoadingUnreads"
          class="d-flex justify-content-center mt-4 mb-4 align-items-center"
        >
          <b-spinner />
        </div>
        <template v-if="!isLoadingUnreads">
          <li
            class="m-4"
            v-for="notification in unreadNotifications"
            :key="notification.id"
          >
            <div class="d-flex align-items-center">
              <b-avatar variant="light-primary" size="46" class="mr-2">
                <feather-icon icon="UserIcon" size="21" />
              </b-avatar>
              <div class="d-flex">
                <!-- <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.data.article_id }}
            </span>
          </p> -->
                <span class="notification-text">
                  {{ notification.data.message }}
                </span>
              </div>
            </div>
          </li>
          <div
            v-if="!isLoadingUnreads && unreadNotifications.length === 0"
            class="d-flex justify-content-center mt-4 mb-4 align-items-center"
          >
            Aucune notification
          </div>
        </template>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="showAllNotifications"
          block
          >Lire toute les notifications</b-button
        >

        <!-- Modal box for ALL the notifications -->
        <b-modal
          ref="all-notifications-modal"
          hide-footer
          title="Toutes les notifications"
        >
          <template v-if="!isLoadingAll" class="allNotificationsList">
            <div
              v-for="notification in allNotifications"
              :key="notification.id"
              class="mt-2"
            >
              <div class="d-flex align-items-center">
                <b-avatar variant="light-primary" size="46" class="mr-2">
                  <feather-icon icon="UserIcon" size="21" />
                </b-avatar>
                <div class="d-flex">
                  <!-- <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.data.article_id }}
            </span>
          </p> -->
                  <span class="notification-text">
                    {{ notification.data.message }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="!isLoadingAll && allNotifications.length === 0"
              class="d-flex justify-content-center mt-2 mb-2 align-items-center"
            >
              Aucune notification
            </div>
          </template>
          <div class="mt-2"></div>
          <div
            v-if="isLoadingAll"
            class="d-flex mb-2 justify-content-center align-items-center"
          >
            <b-spinner />
          </div>
        </b-modal>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BNavItemDropdown,
  BBadge,
  BModal,
  BMedia,
  BLink,
  BSpinner,
  BAvatar,
  BButton,
  BFormCheckbox,
  VBToggle,
} from 'bootstrap-vue'
import { isUserLoggedIn } from '@/auth/utils'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

import { mapActions } from 'vuex'
// store modules and vuex utilities
import notificationsStoreModule from '@/store/notifications'
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    BSidebar,
    BNavItemDropdown,
    BBadge,
    BModal,
    BMedia,
    BLink,
    BSpinner,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    VBToggle,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  data() {
    return {
      // Loading indicator
      isLoadingAll: false,
      isLoadingUnreads: false,

      // Notifications Data
      unreadNotifications: [],
      allNotifications: [],
      newNotifications: 0,
      showModal: false,

      // Pagination datas
      perPage: 10,
      rows: 100,
      perPageValues: [5, 10, 20, 100],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      perPageOptions: [
        { name: '10' },
        { name: '20' },
        { name: '50' },
        { name: '100' },
      ],
    }
  },

  setup() {
    const requiredStoreModules = [
      { path: 'notifications', module: notificationsStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    /* eslint-disable global-require */
    // const notifications = [
    //   {
    //     title: "Congratulation Sam 🎉",
    //     avatar: require("@/assets/images/avatars/6-small.png"),
    //     subtitle: "Won the monthly best seller badge",
    //     type: "light-success",
    //   },
    //   {
    //     title: "New message received",
    //     avatar: require("@/assets/images/avatars/9-small.png"),
    //     subtitle: "You have 10 unread messages",
    //     type: "light-info",
    //   },
    //   {
    //     title: "Revised Order 👋",
    //     avatar: "MD",
    //     subtitle: "MD Inc. order updated",
    //     type: "light-danger",
    //   },
    // ];
    /* eslint-disable global-require */

    // const systemNotifications = [
    //   {
    //     title: 'Server down',
    //     subtitle: 'USA Server is down due to hight CPU usage',
    //     type: 'light-danger',
    //     icon: 'XIcon',
    //   },
    //   {
    //     title: 'Sales report generated',
    //     subtitle: 'Last month sales report generated',
    //     type: 'light-success',
    //     icon: 'CheckIcon',
    //   },
    //   {
    //     title: 'High memory usage',
    //     subtitle: 'BLR Server using high memory',
    //     type: 'light-warning',
    //     icon: 'AlertTriangleIcon',
    //   },
    // ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      requiredStoreModules,
      // notifications,
      // systemNotifications,
      perfectScrollbarSettings,
    }
  },

  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  mounted() {
    // Load the notifications on page mount
    // this.loadNotifications();
    // Fetch the notifications each 1 minute / 60000 milliseconds
    setInterval(this.loadNotifications, 60000)
  },

  methods: {
    ...mapActions('notifications', {
      action_fetchNotifications: 'fetchNotifications',
      action_searchNotifications: 'searchNotifications',
      action_markNotificationAsRead: 'markNotificationAsRead',
    }),
    loadNotifications() {
      const isLoggedIn = isUserLoggedIn()
      if (!isLoggedIn) return
      this.isLoadingUnreads = true

      const params = {
        unread: true,
        // page: this.paginationData.page,
        // per_page: this.paginationData.perPage,
      }

      // Get all unread notifications
      this.action_searchNotifications(params)
        .then((res) => {
          this.isLoadingUnreads = false

          // const newNotificationsCount = res.data.data.length - this.notifications.length;
          this.unreadNotifications = res.data.data
          // console.log(this.unreadNotifications);
          this.newNotifications = res.data.data.length

          // this.newNotifications =
          // newNotificationsCount > 0 ? newNotificationsCount : 0;
        })
        .catch((err) => {
          this.isLoadingUnreads = false
          console.log('Oops! Erreur survenue ', err)
        })
    },

    markUnreadNotificationsAsRead() {
      if (this.newNotifications !== 0) {
        this.newNotifications = 0
        let notificationUnread = this.unreadNotifications
        let unreadArray = []
        for (let i = 0; i < notificationUnread.length; i++) {
          unreadArray.push(notificationUnread[i].id)
        }
        this.action_markNotificationAsRead({
          ids: unreadArray,
        }).catch((err) => {
          console.log('Oops! Erreur survenue ', err)
        })
      }
    },

    showAllNotifications() {
      this.$refs['all-notifications-modal'].show()
      // this.$refs['all-notifications-modal'].hide()

      this.isLoadingAll = true

      const params = {
        // page: this.paginationData.page,
        per_page: this.paginationData.perPage,
      }
      // Get all notifications
      this.action_searchNotifications(params)
        .then((res) => {
          this.isLoadingAll = false

          // const newNotificationsCount = res.data.data.length - this.notifications.length;
          this.allNotifications = res.data.data
          // console.log(this.allNotifications);
          // this.newNotifications =
          // newNotificationsCount > 0 ? newNotificationsCount : 0;
        })
        .catch((err) => {
          this.isLoadingAll = false
          console.log('Oops! Erreur survenue ', err)
        })
    },
  },
}
</script>

<style>
.notification-text {
  font-size: 15px;
}
.allNotificationsList {
  margin: 0 !important;
}
</style>
