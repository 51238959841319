import { ref } from "@vue/composition-api";
import { isNavLinkActive, navLinkProps } from "@core/layouts/utils";

export default function useHorizontalNavMenuLink(item) {
  const isActive = ref(false);
  const linkProps = navLinkProps(item);
  const updateIsActive = () => {
    isActive.value = isNavLinkActive(item);
  };

  // ------------------------------------------------
  // isOpen
  // ------------------------------------------------
  const isOpen = ref(false);

  const updateGroupOpen = (val) => {
    // eslint-disable-next-line no-use-before-define
    isOpen.value = val;
  };

  return {
    isActive,
    updateGroupOpen,
    linkProps,
    updateIsActive,
  };
}
