<template>
  <div
    class="navbar-container d-flex content align-items-center justify-content-between"
  >
    <!-- left -->
    <div>
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link class="nav-link" @click="toggleVerticalMenuActive">
            <feather-icon icon="MenuIcon" size="21" />
          </b-link>
        </li>
      </ul>

      <!-- Left Col -->
      <div
        class="bookmark-wrapper align-items-center flex-grow-1 d-none d-flex d-lg-flex"
      >
        <AppLogo />
        <!-- <bookmarks /> -->
      </div>
    </div>
    <!-- center -->
    <div class="d-flex flex-row">
      <!-- <span class="mr-1 my-auto h3">
        <strong>Menu</strong>
      </span>
      <ul class="nav navbar-nav">
        <li class="nav-item">
          <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
          >
            <i class="las la-bars iconBig"></i>
          </b-link>
        </li>
      </ul> -->
    </div>
    <!-- Right Col -->
    <div class="adjustMargin">
      <!-- <div class="d-flex flex-row">
        <div class="mr-1 my-auto">Choirsir un secteur </div>
        <v-select
          v-model="selectedSector"
          :options="sectorsCustom"
          label="nom"
          class="mr-1" style="width:400px;"
          placeholder="Vous pouvez choisir un secteur d'activités"
        />
      </div> -->
      <b-navbar-nav class="navbar-elements">
        <!-- <li class="mr-1">
          <router-link to="/" class="mr-1">À propos</router-link>
        </li>
        <li class="mr-1">
          <router-link to="/" class="mr-1">Registre des opérateurs</router-link>
        </li>
        <li class="mr-1">
          <router-link to="/" class="mr-1">Articles & Ressources</router-link>
        </li>
        <li class="mr-1">
          <router-link to="/" class="mr-1">FAQ</router-link>
        </li>
        <li class="mr-1">
          <router-link to="/" class="mr-1">Contactez nous</router-link>
        </li>
        <div class="navbar-divider"></div> -->

        <locale />
        <!-- <dark-Toggler class="d-none d-lg-block" /> -->
        <!-- <search-bar /> -->
        <!-- <cart-dropdown /> -->
        <b-button
          v-if="
            isAuthenticated && isOperator && getter_show_new_demande_shortcut
          "
          class="mt-1 float-right my-auto mr-2 ml-1"
          variant="warning"
          @click="
            () => {
              this.$refs['modal-option-demandes'].show()
            }
          "
        >
          <feather-icon icon="PlusIcon" class="mr-1" />
          <span>Nouvelle demande</span>
        </b-button>
        <a
          v-if="isAuthenticated && (isOtAdmin || isAdmin)"
          class="mt-1 float-right my-auto mr-2 ml-1 btn btn-warning"
          variant="warning"
          @click="downloadFile"
        >
          <b-spinner v-if="isDownload == true" small />
          <i class="las la-download mr-1" v-else />

          <span>Télécharger l'application mobile</span>
        </a>
        <notification-dropdown v-if="isAuthenticated" />
        <!-- <b-button
          class="mx-1"
          variant="success"
          @click="action_setter_show(true)"
        >
          <span class="align-middle">Payer</span>
        </b-button> -->
        <b-button
          v-if="!isAuthenticated"
          href="/operateurTouristique"
          variant="primary"
          class="ml-1"
        >
          {{ $t('home.professionnalAccess') }}
        </b-button>
        <user-dropdown v-if="isAuthenticated" />
        <!-- <notification-dropdown v-if="isAuthenticated" /> -->
      </b-navbar-nav>
    </div>
    <!-- modal for notification -->
    <b-modal
      id="modal-option-demandes"
      ref="modal-option-demandes"
      hide-backdrop
      ok-only
      no-close-on-backdrop
      content-class="shadow"
      title="Quelle opération souhaitez-vous effectuer ?"
      ok-title="Ouvrir le formulaire"
      @ok="fillForm()"
    >
      <b-form-group label="">
        <b-form-radio-group v-model="selectedDemandes">
          <b-form-radio
            v-for="(demande, index) in optionsDemandes"
            :key="index"
            name="demande"
            :value="demande"
            class="w-100"
          >
            {{ $t(demande.title) }}
            <i
              v-b-tooltip.hover.top.v-primary="demande.description"
              class="las la-info-circle ml-1"
            />
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BFormInput,
  BButton,
  BModal,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import RegisterButton from '@/components/RegisterButton.vue'
import LoginButton from '@/components/LoginButton.vue'
import AppLogo from '@/components/AppLogo.vue'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'
import localstorageService from '@/services/localstorage/localstorage.service'
import utilsService from '@/services/utils/utils.service'
import paymentsStroreModule from '@/store/payments'
import paramsStoreModule from '@/store/params'
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
import SearchBar from './components/SearchBar.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BLink,
    BFormInput,
    BButton,
    BModal,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BSpinner,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    DarkToggler,
    SearchBar,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    LoginButton,
    RegisterButton,
    AppLogo,
    vSelect,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('auth', {
      isAuthenticated: 'isAuthenticated',
    }),
    ...mapGetters('payments', {
      getter_showPaymentModal: 'getter_show',
      getter_paymentInitialsDatas: 'getter_paymentInitialsDatas',
      getter_isCompleted: 'getter_isCompleted',
    }),
    ...mapGetters('params', {
      getter_show_new_demande_shortcut: 'getter_show_new_demande_shortcut',
    }),
  },
  watch: {
    // $route: {
    //   immediate: true,
    //   async handler(val, old) {
    //   },
    // },
  },
  data() {
    return {
      selectedSector: null,
      sectors: null,
      sectorsCustom: [],
      sectorsTotal: null,
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      isOperator: false,
      isOtAdmin: false,
      isAdmin: false,
      isDownload: false,
      optionsDemandes: null,
      selectedDemandes: null,
      show_dropdown_user: null,
    }
  },
  setup() {
    const requiredStoreModules = [
      { path: 'payments', module: paymentsStroreModule },
      { path: 'params', module: paramsStoreModule },
    ]
    registerStoreModule(requiredStoreModules)
  },
  mounted() {
    const { isOperator, isOtAdmin, isAdmin } = utilsService.currentUserUtils()
    this.isOperator = isOperator
    this.isAdmin = isAdmin
    this.isOtAdmin = isOtAdmin
    if (isOperator) {
      const { listFormulairesBySector } = utilsService.currentUserUtils()
      this.optionsDemandes = listFormulairesBySector
    }

    setTimeout(() => {
      this.show_dropdown_user = true
    }, 100)
  },
  methods: {
    ...mapMutations('payments', {
      action_setter_show: 'setter_show',
    }),
    // --------------------------------------------------
    fillForm() {
      if (!this.selectedDemandes) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Veuillez choisir une demande.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }

      // window.location.href = this.selectedDemandes.demande_link;
      window.open(this.selectedDemandes.demande_link)
      // const { params } = this.selectedDemandes.createRouteProps;
      // return;
      // this.$router.push({
      //   ...this.selectedDemandes.createRouteProps,
      //   params: {
      //     ...params,
      //   },
      // });
    },
    async downloadFile() {
      try {
        this.isDownload = true
        // const fileId = '1ebEO_nxGdP9YAIyNMJl8C7P_sr8D-Gra';
        // const response = await fetch(
        //   'https://drive.google.com/file/d/1ebEO_nxGdP9YAIyNMJl8C7P_sr8D-Gra/view?usp=drive_link'
        // )
        const link = document.createElement('a')
        // link.href =
        //   ''
        // const blob = await response.blob()

        // Créer un lien de téléchargement et le déclencher
        // const url = window.URL.createObjectURL(blob)
        link.href =
          'https://drive.google.com/file/d/1ebEO_nxGdP9YAIyNMJl8C7P_sr8D-Gra/view?usp=drive_link'
        link.download = 'MTCA-POTB.apk' // Remplacer par le nom souhaité
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.isDownload = false
      } catch (error) {
        this.isDownload = false
      }
    },
  },
}
</script>

<style scoped>
.navbar-elements {
  display: flex;
  align-items: center;
}

/* .navbar-link {
  margin-right: 1em;
} */
.navbar-divider {
  margin-right: 1.5em;
  margin-left: 0.5em;
  height: 20px;
  width: 2px;
  background-color: #ddd;
}

/* .navbar-links {
  display: flex;
  align-items: center;
} */

.adjustMargin {
  margin-top: 1.5rem;
}

/* MEDIA QUERIES */
@media (min-width: 1200px) {
  .adjustMargin {
    margin-top: 0;
  }
}
</style>
