<template>
  <li
    v-if="canViewHorizontalNavMenuHeaderLink(item)"
    class="nav-item"
    :class="{
      'sidebar-group-active active': isActive,
      'text-white': isAuthenticated,
    }"
  >
    <b-link
      class="nav-link nav_items_padding"
      :to="{ name: item.route }"
      :class="isAuthenticated && 'text-white'"
    >
      <feather-icon
        v-if="!item.isLaIcon"
        :icon="item.icon || 'CircleIcon'"
        size="24"
      />
      <i v-else class="las" :class="item.laIcon || 'la-circle'" />
      <span class="">{{ t(item.title) }}</span>
    </b-link>
  </li>
</template>

<script>
import { BLink } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useUtils as useAclUtils } from "@core/libs/acl";
import useHorizontalNavMenuHeaderLink from "./useHorizontalNavMenuHeaderLink";
import mixinHorizontalNavMenuHeaderLink from "./mixinHorizontalNavMenuHeaderLink";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BLink,
  },
  mixins: [mixinHorizontalNavMenuHeaderLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, updateIsActive } = useHorizontalNavMenuHeaderLink(
      props.item
    );

    const { t } = useI18nUtils();
    const { canViewHorizontalNavMenuHeaderLink } = useAclUtils();

    return {
      isActive,
      updateIsActive,

      // ACL
      canViewHorizontalNavMenuHeaderLink,

      // i18n
      t,
    };
  },
  computed: {
    ...mapState("auth", {
      isAuthenticated: "isAuthenticated",
    }),
    // -------------------------------------
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.text-white {
  color: #fff;

  &:hover {
    color: #000 !important;
  }
}
</style>
