<template>
  <div v-if="!isRegisterPage && !isVerifyPage">
    <!-- @click="navigateToRegisterEstablishment" -->
    <b-button class="" variant="warning" href="/etablissements/create">
      <span class="align-middle">S' inscrire</span>
    </b-button>

    <!-- <b-modal
      :ref="registerButtonModalRef"
      centered
      title="Vertically Centered"
      hide-footer
      hide-header
      size="lg"
    >

      <b-link class="brand-logo">
        <AppLogo />
      </b-link>

      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h1 class="mb-2">
            S'inscrire en tant que
          </h1>

          <p class="mb-3">
          Sorry for the inconvenience but we're performing some maintenance at
          the moment
        </p>
          <b-row class=" match-height">
            <b-col
              v-for="(item,i) in registrationTypes"
              :key="'item'+i"
              md="12"
              sm="12"
              class="kb-search-content"
            >
              <b-card @click="navigateTo(item)">
                <div class="form-item">
                  <div class="icon-container">
                    <i
                      class="text-primary"
                      :class="item.remixIcon"
                    />
                  </div>

                  <div>
                    <h4 class="form-title">
                      {{ item.title }}
                    </h4>
                  <b-card-text class="mt-1">
                    {{ item.desc }}
                  </b-card-text>
                  </div>
                </div></b-card>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal> -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BLink,
  BFormInput,
  BButton,
  BForm,
  BImg,
  BModal,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

const loremText =
  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis, culpa. Voluptatibus natus aperiam neque libero dolor, recusandae velit voluptatum fugit, molestiae perferendis officia? Culpa quisquam ducimus possimus porro ipsa! Excepturi.";

export default {
  name: "RegisterButton",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BLink,
    BFormInput,
    BButton,
    BForm,
    BImg,
    BModal,
  },

  data() {
    return {
      registerButtonModalRef: "registerButtonModal",
      downImg: require("@/assets/images/pages/under-maintenance.svg"),
      registrationTypes: [
        {
          title: "Etablissements ou agence de voyage",
          desc: loremText,
          route: { name: "operateurs-create" },
          remixIcon: "ri-hotel-fill",
        },
        {
          title: "Voyageur / Client / Touriste",
          desc: loremText,
          route: { name: "register-default" },
          remixIcon: "ri-user-fill",
        },
      ],
    };
  },
  computed: {
    isRegisterPage() {
      return this.$route.name === "operateurs-create";
    },

    isVerifyPage() {
      return this.$route.name === "etablissements-verify-email";
    },
  },
  methods: {
    navigateTo(item) {
      this.$router.push({ ...item.route }).then(this.hideModal());
    },
    navigateToRegisterEstablishment() {
      this.$router.push({ name: "operateurs-create" });
    },
    showModal() {
      this.$refs[this.registerButtonModalRef].show();
    },

    hideModal() {
      this.$refs[this.registerButtonModalRef].hide();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-misc.scss";
.form-item {
  cursor: pointer;
  display: flex;
  gap: 15px;
  align-items: center;
}
.icon-container {
  min-height: 50px;
  height: 50px;
  min-width: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background-color: rgba($color: green, $alpha: 0.5);
  border-radius: 100px;
}
</style>
