import utilsService from "@/services/utils/utils.service";
import dashboard from "./dashboard";
import demandes from "./demandes";
import mes_demandes from "./mes_demandes";
import statistics from "./statistics";
import operateurs from "./operateurs.js";
// import manuelUtilisations from "./manuel-utilisations";
import parametre from "./parametre";
import auto_evaluations from "./auto_evaluations";
import arretes from "./arretes";
import noauth from "./noauth";
import information_client from "./information_client";
import tourism_jobs from "./tourism-jobs";
import rdv_admin from "./rdv-admin";
import appeals from "./appeals";
import appeals_user from "./appeals_user";
import outils_internes from "./outils_internes";
import monEtablis from "./mon-etablis";


const filterRoute = (routes) => (utilsService.isSuperAdmin ? routes : []);
const {
  isOperator,
  isOperatorUEH, isOperatorUEAV, isOperatorUEGT, isOperatorUER,
  isAuthenticated,
  isAdmin,
  isMtcaAdmin,
  isOtAdmin,
  isMinister,
  isMtcaAgent
} = utilsService.currentUserUtils();

let menu = [...dashboard];
!isOperator && menu.push(...demandes);
isOperator && menu.push(...mes_demandes);
!isAuthenticated && menu.push(...noauth);

isOperatorUEH && menu.push(...auto_evaluations);
isOperator && menu.push(...monEtablis);

(isAdmin || isMtcaAdmin || isOtAdmin || isMinister) && menu.push(...outils_internes);

// (isMtcaAdmin || isMtcaAgent) && menu.push(...appeals);
isOperator && menu.push(...appeals_user);

menu.push(
  ...statistics,
  ...parametre
);


export default menu;
