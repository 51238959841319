<template>
  <!-- @mouseenter="() => updateGroupOpen(true)"
  @mouseleave="() => updateGroupOpen(false)" -->
  <li
    v-if="canViewHorizontalNavMenuLink(item)"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
  >
    <hr v-if="item.anHrBefor" />
    <span v-if="!item.isModalPage">
      <!-- for item without sub menu -->
      <b-link v-if="!item.childrens" v-bind="linkProps" class="dropdown-item">
        <feather-icon
          v-if="!item.isLaIcon"
          :icon="item.icon || 'CircleIcon'"
          size="24"
        />
        <i v-else class="las" :class="item.laIcon || 'la-circle'" />
        <span class="menu-title">{{ t(item.title) }}</span>
      </b-link>
      <b-dropdown
        ref="dropdown"
        v-if="item.childrens && item.childrens.length > 0"
        size="sm"
        variant="flat-white"
        class="w-100 nopadding"
        droprigth
        block
      >
        <template #button-content>
          <span class="dropdown-item">
            <feather-icon
              v-if="!item.isLaIcon"
              :icon="item.icon || 'CircleIcon'"
              size="24"
            />
            <i v-else class="las" :class="item.laIcon || 'la-circle'" />
            <span class="menu-title">{{ t(item.title) }}</span>
          </span>
        </template>
        <b-dropdown-item
          v-for="(child, childK) in item.childrens"
          :key="childK"
          class=""
        >
          <b-link
            class="dropdown-item text-left"
            v-bind="linkSubItem(child).value"
          >
            <feather-icon
              v-if="!child.isLaIcon"
              :icon="child.icon || 'CircleIcon'"
              size="24"
            />
            <i v-else class="las" :class="child.laIcon || 'la-circle'" />
            <span class="menu-title">{{ t(child.title) }}</span>
          </b-link>
        </b-dropdown-item>
      </b-dropdown>
    </span>
    <hr v-if="item.anHrAfter" />

    <!-- <b-modal
      :ref="article_create"
      :title="$t('catalogue.entete.title')"
      size="lg"
      hide-footer
      centered
    >
      <product-create-rapide @onAbortAdd="hideAddModal(article_create)" />
    </b-modal> -->
    <!-- <b-modal
      :ref="user_create"
      :title="$t('contacts.entete.title')"
      size="lg"
      hide-footer
      centered
    >
      <user-create-rapide @onAbortAdd="hideAddModal(user_create)" />
    </b-modal> -->
  </li>
</template>

<script>
import {
  BLink,
  BModal,
  BCardText,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCollapse,
  VBToggle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useUtils as useAclUtils } from "@core/libs/acl";
// import ProductCreateRapide from '@/components/ProductCreateRapide.vue'
// import UserCreateRapide from '@/views/apps/annuaire/users-create/UserCreateRapide.vue'
import useHorizontalNavMenuLink from "./useHorizontalNavMenuLink";
import mixinHorizontalNavMenuLink from "./mixinHorizontalNavMenuLink";

export default {
  components: {
    BLink,
    BModal,
    BCardText,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BCollapse,
    // ProductCreateRapide,
    // UserCreateRapide,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  mixins: [mixinHorizontalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      article_create: "article_create",
      user_create: "user_create",
    };
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive, updateGroupOpen } =
      useHorizontalNavMenuLink(props.item);
    const { t } = useI18nUtils();
    const { canViewHorizontalNavMenuLink } = useAclUtils();
    return {
      isActive,
      updateGroupOpen,
      linkProps,
      updateIsActive,
      canViewHorizontalNavMenuLink,
      t,
    };
  },
  computed: {},
  methods: {
    showAddModal(ref) {
      this.$refs[this[ref]].show();
    },
    hideAddModal(ref) {
      this.$refs[this[ref]].hide();
    },
    onOver() {
      this.$refs.dropdown.visible = true;
    },
    onLeave() {
      this.$refs.dropdown.visible = false;
    },
    linkSubItem(item) {
      const { linkProps } = useHorizontalNavMenuLink(item);
      return linkProps;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown:hover .dropdown-menu {
  display: block;
}
</style>
