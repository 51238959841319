export default [
  {
    title: "nav_horizontal.dashboard",
    isLaIcon: true,
    laIcon: "la-chart-bar",
    route: "dashboard",
    action: "access",
    resource: "dashboard",
  },
];
